.sub-menu {
    display: none;
    padding: 0;
    background-color: #191d28;
    opacity: 95%;
    position: fixed;
    z-index: 10;
    box-shadow: 0 20px 20px #191d28;
    max-height: 600px;
    margin: 0 auto;
    overflow-y: scroll;
}

.sub-menu.reward .nav-link {
   position: relative;
}


.box-submenu {
    background: linear-gradient(to bottom, #1f2c23, #1f2c23);
}


.submenu-separator-line {
    margin-top: 20px;
}

.submenu-icon-ttg {
    width: 180px;
    margin: 31px 19px 29px 19px;
}

.submenu-icon-haba {
    width: 170px;
    margin: 31px 29px 32px 30px;
}

.submenu-icon-pp {
    width: 178px;
    margin: 15px 21px 26px 21px;
}

.submenu-icon-sg {
    width: 190px;
    margin: 24px 22px 33px 11px;
}

.submenu-icon-aws {
    width: 180px;
    margin: 45px 22px 45px 22px;
}

.submenu-icon-joker {
    height: 45px;
    margin: 37.5px 68px 37.5px 68px;
}

.submenu-icon-maverick {
    height: 45px;
    margin: 37.5px 55px 37.5px 55px;
}

.submenu-icon-netent {
    height: 30px;
    margin: 50px 68px 40px 68px;
}

.submenu-icon-pngo {
    height: 50px;
    margin: 35px 50px 35px 60px;
}

.submenu-icon-quickspin {
    height: 40px;
    margin: 40px 70px 40px 70px;
}

.submenu-icon-evo {
    height: 118px;
}

.submenu-icon-evo-rng {
    height: 118px;
}

.submenu-icon-mg {
    height: 118px;
}

.submenu-icon-pp-live {
    height: 118px;
}

.submenu-icon-idn {
    height: 118px;
}

.submenu-icon-ia {
    height: 118px;
}

.submenu-icon-toto {
    padding: 30px 30px 30px 30px;
    max-height: 170px;
    min-width: 139px;
    min-height: 170px;
}

.box-submenu:hover .play-button{
    background-color: #1f2c23;
    color: #73b21b;
}

.box-submenu:hover .play-button-toto{
    background-color: #1f2c23 !important;
    color: #73b21b !important;
}

.sub-menu .provider-container {
    position: relative;
}

.sub-menu .provider-container #hover:hover ~ .play-button {
    background-color: #1f2c23;
    color: #73b21b;
}

.sub-menu .provider-container:hover {
    background: linear-gradient(to bottom, #50800d 0%, #73b21b 100%);
    color: #73b21b;
}

.sub-menu .provider-container .play-button {
    border-radius: 25px;
    position: absolute;
    left: 50px;
    top: 175px;
    width: 90px;
    background: linear-gradient(to bottom, #50800d 0%, #73b21b 100%);
    color: #FFFFFF;
    font-size: 0.75rem;
}

.sub-menu .provider-container .play-button-toto {
    border-radius: 25px;
    position: absolute;
    left: 24px;
    top: 140px;
    width: 90px;
    background: linear-gradient(to bottom, #50800d 0%, #73b21b 100%);
    color: #FFFFFF;
    font-size: 0.75rem;
}

.box-submenu:hover{
    background: linear-gradient(to bottom, #50800d, #73b21b);
}

.box-submenu:hover .play-button{
    background: #1f2c23;
    color: #73b21b;
}

.sub-menu .provider-container .play-button .btn-text {
    font-weight: bold;
}

.sub-menu .provider-container .play-button-toto .btn-text {
    font-weight: bold;
}

.sub-menu .provider-container .maintenance-button {
    border-radius: 25px;
    position: absolute;
    left: 50px;
    top: 175px;
    width: 104px;
    background-color: #fd0000;
    color: #FFFFFF;
    font-size: 0.75rem;
}

.sub-menu .provider-container .maintenance-button:hover {
    cursor: default;
}

